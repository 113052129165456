import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5225HP() {
    const productDetail = {
        code: '5225HP',
            name: '5225HP',
            image: 'germanygates/23_5225HP.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 1,8mm, chân chịu lực dày 2mm. TL 15,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật lớn. Bề mặt nan có 2 cụm 3 chỉ nhuyễn.',
        maximumSize: '7.2m x 6m',
        category: 'Cửa cuốn Đức',
        price: "4.040.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
