import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G589RS() {
    const productDetail = {
        code: '589RS',
        name: '589RS',
        image: 'germanygates/2_589RS.png',
        structure: 'Nan hình hộp bản 58mm, móc dày 1,1mm, chân chịu lực dày 1,1mm. TL 9,5Kg ± 6%',
        characteristic: 'Nan hình hộp, có ron chống ồn 2 chiều, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có rãnh lõm 8mm và 2 sọc 2 bên nằm giữa thân nan.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.916.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
