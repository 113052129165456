import React from "react";
import Footer from "../footer/footer";
import "./lienhe.css";
export default function Contact() {
  return (
    <>
      <div className="container">
        <div className="row">
            <b style={{ fontSize: "36px", color: "#1d4794" }}>Liên Hệ</b>
        </div>
        <div>
          HITADOOR luôn tìm kiếm khách hàng khắp mọi miền Việt Nam với chính
          sách hợp lý, giá ưu đãi
        </div>
        <div>
          Quý đại lý vui lòng liên hệ số điện thoại:
        </div>
          <div className="d-inline" > &nbsp; &nbsp; &nbsp; &nbsp;Nhà máy</div>
          <div className="d-inline">: 0918 045 005</div><br/>
          <div className="d-inline"> &nbsp; &nbsp; &nbsp; &nbsp;Phòng kinh doanh</div>
          <div className="d-inline">: 0918 680 690</div>

        <div >
          Hoặc Quý đại lý - Quý khách hàng có thể đến xem trực tiếp tại Showroom
        </div>
        <div>
          Địa chỉ: 826 Quốc Lộ 13, phường Thới Hoà, Thành phố Bến Cát, Tỉnh
          Bình Dương
        </div>
      </div>
      <Footer />
    </>
  );
}
