import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G528HE() {
    const productDetail = {
        code: '528HE',
        name: '528HE',
        image: 'germanygates/16_528HE.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 1mm, chân chịu lực dày 1mm. TL 8,5Kg ± 6%',
        characteristic: '2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 2 sọc chia đều thân nan.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.205.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
