import React from "react";
import Footer from "../footer/footer";
export default function RecruitmentNVTT() {
  return (
    <>
      <div className="container recruitment_content">
        <div class="d-flex justify-content-center">
          <h1 style={{ color: "#1d4794" }}>
            Tuyển Dụng Nhân Viên Nghiên Cứu và Phát Triển Thị Trường
          </h1>
        </div>
        <div>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <img
            style={{ width: "200px", height: "28px" }}
            src="/logohitatrangden.png"
          ></img>{" "}
          đang tìm kiếm các ứng viên năng động, nhiệt huyết để gia nhập đội ngũ
          nhân viên nghiên cứu và phát triển thị trường nhằm mở rộng và phát
          triển thị trường. Nếu bạn có đam mê kinh doanh, thích giao tiếp và
          muốn phát triển sự nghiệp trong lĩnh vực cửa và vật liệu xây dựng, hãy
          nộp đơn ứng tuyển ngay!
        </div>
        <ul>
          <li>
            <b>Số lượng tuyển dụng: </b>4 nhân viên
          </li>
          <li>
            <b>Yêu Cầu Công Việc</b>
          </li>
          <div>
            <b>- Trình độ:</b> Trung cấp trở lên.
          </div>
          <div>
            <b>- Ngành</b> Quản trị kinh doanh.
          </div>
          <div>
            <b>- Kinh nghiệm:</b> Ưu tiên có kinh nghiệm phân phối vật liêu lĩnh vực xây dựng hoặc đã làm vị trí tương đương.
          </div>
          <div>
            <b>- Ưu tiên:</b> Có kinh nghiệm phân phối trong ngành cửa cuốn và tấm ốp tường nhựa.
          </div>
          <div>
            <b> - Tính cách: </b>Có tính cầu tiến và chịu khó trong công việc
          </div>
          <li>
            <b>Quyền lợi</b>
          </li>
          <div>
            <b> - Lương + Thưởng:</b> Lương cơ bản + doanh số bán hàng
          </div>
          <div>
            <b> - Phúc lợi:</b> Bảo hiểm xã hội, bảo hiểm y tế theo chính sách
            lao động của nhà nước.
          </div>
        </ul>
        <b>Vui lòng liên hệ chúng tôi để biết thêm chi tiết: </b>
        <b>0913 211 616 (Mr. Phi)</b>
      </div>
      <Footer />
    </>
  );
}
