import React from 'react'
import UcGateDetail from './ucgateDetail'
export default function ECO1() {
    const productDetail = {
        code: 'eco_1',
        name: 'ECO_1',
        image: 'ucgates/eco_1/eco1b2.png',
        category: 'Cửa cuốn Úc',
        image1: 'ucgates/eco_1/eco1b2.png',
        image2: 'ucgates/eco_1/eco1b3.png',
        image3: 'ucgates/eco_1/eco1e1.png',
        image4: 'ucgates/eco_1/eco1e3.png',
        image5: 'ucgates/eco_1/eco1e4.png',
        doorSlat: 'Thép hợp kim liên doanh Đài Loan, dày 0,45mm (mạ sơn bóng, phủ polyeste). ',
        color: ' Xanh ngọc - Kem; Xám - Café; Kem mờ - Xám mờ',
        maximumSize: '4,5m x 4m',
        navRay: 'Ray sắt U65 x 1,2mm',
        price: "980.000",
    }
    return (
        <UcGateDetail productDetail={productDetail} />
    )
}
