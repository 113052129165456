import React from "react";
import Footer from "../footer/footer";
export default function Top100() {
  const data = {
    code: "hitadoor-dat-top-100-thuong-hieu-chau-a-thai-binh-duong",
    name: "Hitadoor Đạt Top 100 Thương Hiệu ",
    name2: "Châu Á - Thái Bình Dương Vào Ngày 22/10/2022",
    image: "tintuc/top100/2.jpg",
    image1: "tintuc/top100/2.1.png",
    image2: "tintuc/top100/2.2.jpg",
  };
  return (
    <>
      <div className="container">
        <h2
          className="d-flex d-flex justify-content-center"
          style={{ color: "#1d4794" }}
        >
          {data.name}
        </h2>
        <h2
          className="d-flex d-flex justify-content-center"
          style={{ color: "#1d4794" }}
        >
          {data.name2}
        </h2>
        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor đã vinh dự đạt được danh
          hiệu Top 100 Thương Hiệu Châu Á - Thái Bình Dương vào ngày 22/10/2022.
          Đây là một cột mốc quan trọng, ghi nhận sự nỗ lực không ngừng của công
          ty trong việc nâng cao chất lượng sản phẩm và dịch vụ, cũng như khẳng
          định vị thế của mình trên thị trường quốc tế.
        </div>
        <br/>

        <ul>
          <b> Những Thành Tựu Nổi Bật Của Hitadoor:</b>
          <br />
          <br />
          <li>
            <b>Chất Lượng Đỉnh Cao: </b>Hitadoor luôn cam kết mang đến những sản
            phẩm cửa chất lượng cao, bền bỉ và an toàn.
          </li>
          <li>
            <b>Công Nghệ Hiện Đại: </b>Áp dụng những công nghệ tiên tiến nhất
            trong sản xuất và quản lý.
          </li>
          <li>
            <b>Thị Trường Quốc Tế: </b>Mở rộng thị trường và thâm nhập vào các
            quốc gia trong khu vực Châu Á - Thái Bình Dương.
          </li>
          <li>
            <b>Khách Hàng Hài Lòng:</b>Được khách hàng tin tưởng và đánh giá cao
            về chất lượng sản phẩm và dịch vụ.
          </li>
          
        </ul>
        <div className="d-flex justify-content-center">
            <img
            className="image_tintuc_top100_huychuong"
              src={`/${data.image1}`}
              alt={data.name}
            />
          </div>
          <br />
          <ul>
          <b>Ý Nghĩa Của Danh Hiệu:</b>
          </ul>
          <p>
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Việc đạt được danh hiệu Top 100
            Thương Hiệu Châu Á - Thái Bình Dương không chỉ là sự công nhận cho
            những nỗ lực không ngừng của Hitadoor mà còn là động lực để công ty
            tiếp tục phát triển và hoàn thiện hơn nữa. Đây cũng là minh chứng
            cho uy tín và chất lượng mà Hitadoor đã xây dựng và duy trì trong
            suốt thời gian qua.
          </p>
          <div className="d-flex justify-content-center ">
            <img
            className="image_tintuc_top100"
              style={{ height: "auto" }}
              src={`/${data.image2}`}
              alt={data.name}
            />
          </div>
          <br />
          <ul>
          <b> Lời Cảm Ơn Từ Hitadoor:</b>
          </ul>
          <p>
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Việc đạt được danh hiệu Top 100
            Thương Hiệu Châu Á - Thái Bình Dương không chỉ là sự công nhận cho
            những nỗ lực không ngừng của Hitadoor mà còn là động lực để công ty
            tiếp tục phát triển và hoàn thiện hơn nữa. Đây cũng là minh chứng
            cho uy tín và chất lượng mà Hitadoor đã xây dựng và duy trì trong
            suốt thời gian qua.Hitadoor xin gửi lời cảm ơn sâu sắc đến tất cả
            các đối tác, khách hàng và toàn thể nhân viên đã luôn đồng hành và
            ủng hộ. Sự công nhận này là động lực để chúng tôi tiếp tục phấn đấu,
            mang đến những sản phẩm và dịch vụ tốt nhất.
          </p>
      </div>
      <Footer />
    </>
  );
}
