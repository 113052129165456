import React from "react";
import Footer from "../../footer/footer";
import GermanyAdvantage from "./germanyAdvantage";
export default function germanyGateDetail(props) {
  const productDetail = props.productDetail;
  return (
    <>
      <div className="container mt-2">
        <div className="row product">
          <div className=" col-6 responsive_grid_item">
            <img
              className="responsive_item_img"
              style={{ height: "400px" }}
              alt={`${productDetail.code}`}
              src={`/${productDetail.image}`}
            />
          </div>
          <div className="detail mt-3 col-5 responsive_grid_item">
            <h1
              className="row d-flex justify-content-center"
              style={{ color: "#1d4794" }}
            >{`${productDetail.name}`}</h1>
            <div className="row d-inline">
              <b>Giá sản phẩm:</b>
              <span style={{ color: "red" }}>
                {`${productDetail.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
            <br />
            <div className="row d-inline">
              <b>Kết cấu:</b>
              {`${productDetail.structure}`}
            </div>
            <br />
            <div className="row d-inline">
              <b>Đặc điểm:</b>
              {`${productDetail.characteristic}`}
            </div>
            <br />
            <div className="row d-inline">
              <b>Kích thước tối đa(WxH):</b> {`${productDetail.maximumSize}`}
            </div>
          </div>
        </div>
        <GermanyAdvantage />
        <div className=" mb-4 productLine"></div>
        <div class="video-container">
          <video controls>
            <source src="/video.mp4" type="video/mp4" />
            Trình duyệt của bạn không hỗ trợ video.
          </video>
        </div>
      </div>
      <Footer />
    </>
  );
}
