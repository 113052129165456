import React from 'react'
import UcGateDetail from './ucgateDetail'
export default function BMPP() {
    const productDetail = {
        code: 'b_mpp',
        name: 'B_MPP',
        image: 'ucgates/b_mpp/bmppb1.png',
        category: 'Cửa cuốn Úc',
        image1: 'ucgates/b_mpp/bmppb1.png',
        image2: 'ucgates/b_mpp/bmppb2.png',
        image3: 'ucgates/b_mpp/bmppb3.png',
        image4: 'ucgates/b_mpp/bmppb1.png',
        image5: 'ucgates/b_mpp/bmppb2.png',
        doorSlat: 'Thép hợp kim mạ màu hãng BlueScope (MCPP), dày 0,5mm (mạ sơn mờ, phủ polyeste).',
        color: ' Kem mờ - Xanh rêu; Xám mờ - Trắng mờ',
        maximumSize: '5m x 5m',
        navRay: 'Ray sắt U65 x 1,2mm',
        price: "1.100.000",
    }
    return (
        <UcGateDetail productDetail={productDetail} />
    )
}
