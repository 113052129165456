import React from "react";
import "./accessoryGate.css";
import Footer from "../../footer/footer";
import { DataView } from "primereact/dataview";
export default function AccessoryGate() {
  const Accessory = [
    {
      code: "hiteco",
      name: "Bộ tời ngoài HITECO",
      image: "phukiens/phukiengermany/hiteco.png",
      category: "Phụ kiện cửa Đức",
      price: "5.790.000",
    },
    {
      code: "puma",
      name: "Bộ tời ngoài PUMA",
      image: "phukiens/phukiengermany/puma.png",
      category: "Phụ kiện cửa Đức",
      price: "4.340.000",
    },
    {
      code: "rito",
      name: "Bộ tời ngoài RITO",
      image: "phukiens/phukiengermany/rito.png",
      category: "Phụ kiện cửa Đức",
      price: "5.390.000",
    },
    {
      code: "yh",
      name: "Bộ tời ngoài YH",
      image: "phukiens/phukiengermany/yh.png",
      category: "Phụ kiện cửa Đức",
      price: "6.180.000",
    },
    {
      code: "botoingoaitocdocao",
      name: "Bộ tời ngoài tốc độ cao",
      image: "phukiens/phukienuc/botoingoaitocdocao.png",
      category: "Phụ kiện cửa Úc",
      price: "11.580.000",
    },
    {
      code: "binhluudien",
      name: "Bình Lưu Điện",
      image: "phukiens/phukien/binhluudien.jpg",
      category: "Phụ kiện cửa Úc",
      price: "3.950.000",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className=" card  responsive_grid_item col-4" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/san-pham/${product.code}`}
        >
          <div className="p-4">
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "300px" }}
                src={`/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="row">
              <div className="col"></div>
              <div
                className="d-flex justify-content-center col-7 text-2xl font-bold"
                style={{ color: "blue", fontWeight: "bold" }}
              >
                {product.name}
              </div>
              <div className="col"></div>
              <span style={{ color: "red" }}>
                {`${product.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className="d-flex justify-content-center">
          <b style={{ fontSize: "36px", color: "red" }}>Phụ kiện</b>
        </div>
        <DataView
          className="container"
          value={Accessory}
          listTemplate={listTemplate}
        />
      </div>
      <Footer />
    </>
  );
}
