import React from "react";
import Footer from "../../footer/footer";

export default function Cuacuontrongsuot() {
  const productDetail = {
    code: "cuacuontrongsuot",
    name: "Cửa cuốn trong suốt",
    image: "cuacuontrongsuot/1.png",
    category: "Cửa cuốn khác",
    price: "2.250.000",
  };
  return (
    <>
      <div className="container mt-2">
        <div className="row product">
          <div className=" col-7 mt-5 responsive_grid_item">
            <img
            className="responsive_item_img"
              style={{  height: "500px" }}
              alt={`${productDetail.code}`}
              src={`/${productDetail.image}`}
            />
          </div>
          <div className="detail mt-3 col-5 responsive_grid_item">
            <h1
              className="row d-flex justify-content-center"
              style={{ color: "#1d4794" }}
            >{`${productDetail.name}`}</h1>
             <div className="row d-inline">
              <b>Giá sản phẩm:</b>
                {" "}
              <span style={{ color: "red" }}>
                {`${productDetail.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
            <br />
            <div>
              {" "}&nbsp; &nbsp; &nbsp; &nbsp;
              Được sản xuất bằng chất liệu nhôm cao cấp 6063 cho độ bền của cửa
              cao, độ cứng cáp tốt nhờ vào độ dày nhôm 1.2mm (±5%) giúp cửa bền,
              chắc chắn. Cửa cuốn Hitadoor trong suốt được áp dụng quy trình xử
              lý bề mặt thanh nan cửa bằng công nghệ Nano đạt tiêu chuẩn quốc
              tế, được sơn tĩnh điện cao cấp chống ô xi hóa và được phủ 1 lớp
              Metalic chống phai màu cao cấp nhất hiện nay tại Việt Nam.
            </div>
            <div>&nbsp; &nbsp; &nbsp; &nbsp;
              Sử dụng lớp màn trong suốt với chất liệu nhựa Polycarbonate chất
              lượng cao, giúp phản chiếu ánh sáng tốt, làm tăng thêm độ sáng cho
              các sản phẩm trang trí bên trong trở nên sang trọng, đẹp hơn. Lớp
              màn trong suốt này có độ bền tốt, rất khó bị thủng, rách, đồng
              thời giúp người bên trong có thể quan sát ra bên ngoài một cách
              bao quát và dễ dàng.
            </div>
            <div>&nbsp; &nbsp; &nbsp; &nbsp;
              Có thể lắp đặt tự động, kết hợp motor cửa cuốn và remote cửa cuốn
              để sử dụng, điều khiển từ xa với khoảng cách lên đến 50m, thuận
              tiện cho việc vận hành cửa đóng mở hằng ngày. Nếu xảy ra cúp điện,
              quý khách có thể dễ dàng tích hợp với bình lưu điện cửa cuốn để
              vận hành cửa lên xuống bằng điều khiển cửa cuốn từ xa.
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-center">
          <b style={{ fontSize: "36px" }}>Hình ảnh cửa cuốn trong suốt</b>
        </div>
        <div class="d-flex  justify-content-center">
          <div className="col-8 responsive_grid_item">
            {" "}
            <img
              className="image_"
              src="/cuacuontrongsuot/2.JPG"
              alt="something"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
