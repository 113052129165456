import React from 'react'
import Footer from '../../footer/footer'
export default function AcessoryDetail(props) {
    const productDetail = props.productDetail;
    return (
        <>
            <div className='container mt-2'>
            <h1 style={{color:'#1d4794'}}>{`${productDetail.category}`}</h1>
                <div className='row product'>
                    <h4 style={{color:'#1d4794'}}>{`${productDetail.name}`}</h4>
                    <div className=' col-5 responsive_grid_item' >
                        <img style={{ width: '100%', height: '400px' }} alt={`${productDetail.code}`} src={`/${productDetail.image}`} />
                    </div>
                    <div className='col'></div>
                    <div className='detail  col-6 responsive_grid_item' >
                        <img  className='image_accessory_detail'alt={`${productDetail.code}`} src={`/${productDetail.imageDetail}`} />
                    </div>         
                </div>
            </div>
            <Footer />

        </>
    )
}
