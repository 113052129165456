import React from 'react'
import UcGateDetail from './ucgateDetail'
export default function BCOLO() {
    const productDetail = {
        code: 'b_colo',
        name: 'B_COLO',
        image: 'ucgates/b_colo/bcolob4.png',
        category: 'Cửa cuốn Úc',
        image1: 'ucgates/b_colo/bcolob1.png',
        image2: 'ucgates/b_colo/bcolob4.png',
        image3: 'ucgates/b_colo/bcolob1.png',
        image4: 'ucgates/b_colo/bcolob4.png',
        image5: 'ucgates/b_colo/bcolob1.png',
        doorSlat: 'Thép hợp kim mạ màu hãng BlueScope, dày 0,6mm (mạ sơn mờ, phủ polyeste). ',
        color: 'Trắng mờ - Xanh mờ',
        maximumSize: '6,2m x 5m',
        navRay: 'Ray sắt U65 x 1,2mm',
        price: "1.400.000",
    }
    return (
        <UcGateDetail productDetail={productDetail} />
    )
}
