import React from "react";

export default function GermanyAdvantage() {
  return (
    <>
      <div className="row">
        <div className="mt-3 col align-self-center responsive_grid_item">
          <h1 style={{ color: "#1d4794" }}>ƯU ĐIỂM CỬA CUỐN KHE THOÁNG HỢP KIM NHÔM</h1>
          <p>-Là dòng cửa cuốn thế hệ mới, vận hành êm ái và bền đẹp.</p>
          <p>-Thiết kế hài hoà màu sắc, làm tăng giá trị ngôi nhà</p>
          <p>
            -Đóng mở cửa bằng Remote tiện lợi, thông minh, có thể sử dụng điện
            thoại Smartphone
          </p>
          <p>
            -Tích hợp được với các phụ kiện cảm biến đảo chiều khi gặp vật cản
          </p>
          <p>
            -Cửa cuốn được ứng dụng làm cửa đi chính, cửa hàng, cửa garage ô tô,
            kiốt, công trình biệt thự, khu công nghiệp.
          </p>
        </div>
        <div className="col-5 align-self-center responsive_grid_item">
          <img
            style={{ height: "500px" }}
            src="/advantageGermany.jpg"
            alt="something"
          />
        </div>
      </div>
    </>
  );
}
