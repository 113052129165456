import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G518HE() {
    const productDetail = {
        code: '518HE',
        name: '518HE',
        image: 'germanygates/15_518HE.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 0,9mm, chân chịu lực dày 0,9mm. TL 7,8Kg ± 6%',
        characteristic: '2 chân, 1 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 4 sọc nằm giữa.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.040.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
