import React from "react";
import Footer from "../footer/footer";
import "../tintuc/tintuc.css";
export default function Khaitruong() {
  const data = {
    code: "hitadoor-khai-truong-showroom-tai-binh-duong",
    name: "Hitadoor Khai Trương Showroom",
    name2: "Mới Vào Tháng 3, năm 2022",
    image: "tintuc/khaitruong/1.jpg",
    image1: "tintuc/khaitruong/1.1.jpg",
  };

  return (
    <>
      <div className="container">
      <h2
          className="d-flex d-flex justify-content-center"
          style={{ color: "#1d4794" }}
        >
          {data.name} 
        </h2>
        <h2
          className="d-flex d-flex justify-content-center"
          style={{ color: "#1d4794" }}
        >
          {data.name2}
        </h2>

        <div className="newContent">
          <div className="contentParagraph">
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor đã chính thức khai trương
            showroom mới vào đầu năm 2022 , đánh dấu một bước phát triển quan
            trọng trong hành trình kinh doanh của mình. Showroom mới này không
            chỉ mở rộng không gian trưng bày sản phẩm mà còn cung cấp những trải
            nghiệm mua sắm tốt nhất cho khách hàng.
          </div>
        </div>
        <div className="newContent">
          <div className="contentParagraph">
            <ul>
              <li>
                <b>Sản phẩm: </b>Hita trưng bày đầy đủ các dòng sản phẩm chất
                lượng cao, từ các mẫu cửa truyền thống đến những thiết kế hiện
                đại và sáng tạo.
              </li>
              <li>
                <b>Dịch Vụ: </b>Đội ngũ nhân viên chuyên nghiệp và nhiệt tình
                luôn sẵn sàng hỗ trợ khách hàng, tư vấn những giải pháp tốt
                nhất.
              </li>
              <div className="d-flex justify-content-center mt-4">
                <img
                className="image_tintuc"
                  style={{  height: "auto" }}
                  src={`/${data.image1}`}
                  alt={data.name}
                />
              </div>
              <br />
              <b>Lợi Ích Khi Tham Quan Showroom Mới:</b>
              <br />
              <br />
              <li>
                <b>Trải Nghiệm Thực Tế: </b>Khách hàng có thể trực tiếp xem và
                cảm nhận chất lượng sản phẩm.
              </li>
              <li>
                <b>Tư Vấn Chuyên Nghiệp:</b>Được tư vấn tận tình bởi đội ngũ
                nhân viên giàu kinh nghiệm.
              </li>
              <li>
                <b>Ưu Đãi Đặc Biệt:</b> Nhiều chương trình khuyến mãi và ưu đãi
                hấp dẫn dành cho khách hàng khi mua sắm tại showroom.
              </li>
              <br />
              <b>Lời Mời Từ Hita:</b>
              <br />
             
            </ul>
            <div>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor trân trọng mời quý
                khách hàng đến tham quan và trải nghiệm tại showroom mới của
                chúng tôi. Chúng tôi cam kết mang đến cho bạn những sản phẩm và
                dịch vụ tốt nhất, đáp ứng mọi nhu cầu và mong đợi.
              </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
