import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function HITECO() {
    const productDetail = {
        code: 'hiteco',
            name: 'Bộ tời ngoài HITECO',
            image: 'phukiens/phukiengermany/hiteco.png',
            category: 'Phụ kiện cửa Đức',
            imageDetail: 'phukiens/phukiengermany/hitecocontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
