import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function BLD() {
    const productDetail = {
        code: 'binhluudien',
        name: 'Bình Lưu Điện',
        image: 'phukiens/phukien/binhluudien.jpg',
        category: 'Phụ kiện cửa Đức và Úc',
        imageDetail:'phukiens/phukien/binhluudiencontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
