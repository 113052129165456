import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function BTNGTDC() {
    const productDetail = {
        code: 'botoingoaitocdocao',
        name: 'Bộ tời ngoài tốc độ cao',
        image: 'phukiens/phukienuc/botoingoaitocdocao.png',
        category: 'Phụ kiện cửa Úc',
        imageDetail:'phukiens/phukienuc/phukienuccontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
