import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G71HC() {
    const productDetail = {
        code: '71HC',
        name: '71HC',
        image: 'germanygates/27_71HC.png',
        structure: 'Nan hình hộp bản 71mm, móc dày 1,3mm, chân chịu lực dày 1,2mm. TL 10,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, khe thoáng đồng tiền. Bề mặt nan có 2 cụm sọc.',
        maximumSize: '6m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.900.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
