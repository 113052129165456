import React from "react";
import "./banggiacuacuon.css";
import Footer from "../footer/footer";
export default function Banggiacuacuon() {
  return (
    <>
      <div className="container">
        <div class="d-flex justify-content-center">
          <h1 style={{ color: "#1d4794", fontWeight: "bold" }}>
            Bảng báo giá cửa cuốn
          </h1>
        </div>
        <div class="d-flex align-content-center flex-wrap">
          <img
            className="image"
            src="/banggiacuacuon/1.png"
            alt="something"
          />
           <img
            className="image"
            src="/banggiacuacuon/2.png"
            alt="something"
          />
           <img
            className="image"
            src="/banggiacuacuon/3.png"
            alt="something"
          />
           <img
            className="image"
            src="/banggiacuacuon/4.png"
            alt="something"
          />
        </div>
        <br/>
        <div class="d-flex justify-content-center">
          <h1 style={{ color: "#1d4794", fontWeight: "bold" }}>
            Bảng báo giá motor và phụ kiện cửa cuốn, cửa công
          </h1>
        </div>
        <div class="d-flex align-content-between flex-wrap">
        <img
            className="image"
            src="/banggiacuacuon/5.png"
            alt="something"
          />
           <img
            className="image"
            src="/banggiacuacuon/6.png"
            alt="something"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
