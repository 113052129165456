import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G558RS() {
    const productDetail = {
        code: '558RS',
        name: '558RS',
        image: 'germanygates/6_558RS.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,1mm, chân chịu lực dày 1mm. TL 8,8Kg ± 6%',
        characteristic: '2 ron giảm chấn, 3 chân, 1 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có cụm sọc nhỏ.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.830.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
