import React from "react";
import Footer from "../../footer/footer";
import { Divider } from "primereact/divider";
import { DataView } from "primereact/dataview";
export default function Cuacuonkhac() {
  const OtherGates = [
    {
      code: "cuacuonluoi",
      name: "Cửa cuốn lưới",
      image: "cuacuonluoi/1.jpg",
      category: "Cửa cuốn khác",
      price: "1.150.000",
    },
    {
      code: "cuacuontrongsuot",
      name: "Cửa cuốn trong suốt",
      image: "cuacuontrongsuot/1.png",
      category: "Cửa cuốn khác",
      price: "2.250.000",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className=" card responsive_grid_item col-4" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/san-pham/${product.code}`}
        >
          <div className="p-4">
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "300px" }}
                src={`/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="row">
              <div className="col"></div>
              <div
                className="d-flex justify-content-center col-7 text-2xl font-bold"
                style={{ color: "blue", fontWeight: "bold" }}
              >
                {product.name}
              </div>
              <div className="col"></div>
              <span style={{ color: "red" }}>
                {`${product.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div>
        <Divider align="center">
          <div className="inline-flex align-items-center">
            <b style={{ fontSize: "20px", color: "#1d4794" }}>Cửa cuốn khác</b>
          </div>
        </Divider>
        <DataView
          className="container"
          value={OtherGates}
          listTemplate={listTemplate}
        />
      </div>
      <Footer />
    </>
  );
}
