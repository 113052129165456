import { Component } from "react";
import React from "react";
import Footer from './../footer/footer';
import './introduce.css'
class introduce extends Component {
    state = {}
    render() {
        return (
            <><div className="container-fluid introduce">
                <div className="videoYoutube d-flex justify-content-center">
                    <iframe width="800"
                        height="500"
                        src="https://www.youtube.com/embed/cJfeCURW6oI?si=7HrZJksnS4HC5Lqr"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        className="responsive_youtube"
                        allowfullscreen></iframe>
                </div>
                <div className="content">
                    <br/>
                    <h3>TẬP ĐOÀN CỬA CUỐN AN TOÀN & THÔNG MINH HÀNG ĐẦU VIỆT NAM</h3>
                    <br />
                    <p> &nbsp; &nbsp; &nbsp; &nbsp;Vào năm 2003, Hitadoor bắt đầu xuất hiện trên thị trường Việt Nam. Qua thời gian,
                        Hitadoor đã không ngừng phát triển và khẳng định vị thế dẫn đầu toàn quốc trong lĩnh vực sản xuất và kinh doanh cửa cuốn chất lượng cao,
                        an toàn và thông minh. Hiện nay, cửa cuốn Hitadoor đã trở thành lựa chọn an toàn và thông minh của người dân Việt,
                        tự hào là doanh nghiệp đạt danh hiệu Thương hiệu Quốc gia hai lần liên tiếp,
                        ghi dấu ấn thương hiệu tại 63 tỉnh thành trên cả nước và không ngừng mở rộng hoạt động ra các thị trường quốc tế.
                        Trong suốt chặng đường phát triển, Hitadoor luôn luôn khai thác điểm mạnh,
                        không ngừng phát triển công nghệ để cho ra các sản phẩm ngày càng hiện đại gắn liền với sự an toàn của người tiêu dùng.
                    </p>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col">
                        <img className="image_number" src="/tinhthanh.png" alt="something" />
                    </div>
                    <div className="col-4">
                        <img className="image_number" src="/nhanvien.png" alt="something" />
                    </div>
                    <div className="col"></div>
                </div>
                <div className="row mt-2">
                    <div className="col"></div>
                    <div className="col-6"><img className="image_number" src="/sanpham.png" alt="something" /></div>
                    <div className="col"></div>
                </div>
                <hr />
                <h3 className="content">ĐỘT PHÁ CÔNG NGHỆ CỬA CUỐN THÔNG MINH</h3>
                <p className="content"> &nbsp; &nbsp; &nbsp; &nbsp;Những ứng dụng công nghệ mới nhất của Hitadoor giúp cửa vận hành êm ái nhờ hoạt động của ROM GIẢM CHẤN CHIỀU,
                    an toàn với chế độ TỰ DỪNG KHI GẶP VẬT CẢN được vận hành thông qua BỘ ĐIỀU KHIỂN THÔNG MINH tiện lợi, nhanh chóng.
                </p>
                <div class="row">
                    <div className="col"></div>
                    <div class="col-7"><img style={{ width: '100%', height: '100%' }} src="/gioithieu1.png" alt="something" /></div>
                </div>
                <hr />
                <div class="d-flex flex-row-reverse">
                    <h3 className="content">TẬN TÂM VỚI KHÁCH HÀNG</h3>
                </div>
                <div class="d-flex flex-row-reverse">
                    <p className="content"> &nbsp; &nbsp; &nbsp; &nbsp;Sứ mệnh của Hitadoor không chỉ đem tới cho ngôi nhà những
                        tiện ích tốt nhất mà còn trở thành một người bạn chu đáo, tin cậy của mọi gia đình.
                    </p>
                </div>
                <div className="row">
                    <div className="col-7"><img src="/gioithieu2.jpg" style={{ width: "100%", height: '100%' }} alt="something" /></div>
                    <div className="col d-flex align-items-end"> <img style={{ width: "100%", height: '20%' }} src="/phantram.png" alt="something" /></div>

                </div>

                <hr />
                <h3 className="content">XÂY DỰNG ĐỐI TÁC BỀN VỮNG
                </h3>
                <p className="content">
                &nbsp; &nbsp; &nbsp; &nbsp;Tinh thần Uy tín - Trân trọng -
                    Phát tiển bền vững là những giá trị cốt lõi mà Hitadoor luôn nỗ lực xây dựng và duy trì trong mối quan hệ với các đối tác và đại lý.
                </p>
                <br />
                <div className="row">
                    <div className="col">
                    </div>
                    <div className="col">
                        <img className="image_number" src="/nhaphanphoi.png"alt="something" />
                    </div>
                    <div className="col">
                        <img className="image_number" src="/diemban.png" alt="something"/>
                    </div>
                    <div className="col">
                    </div>
                </div>
                <br />
                <br />
                <hr />
                <div class="d-flex flex-row-reverse">
                    <h3 className="content">TRÂN TRỌNG GIÁ TRỊ NIỀM TIN
                    </h3>
                </div>
                <div class="d-flex flex-row-reverse">
                    <p className="content">&nbsp; &nbsp; &nbsp; &nbsp;Sự tin tưởng của khách hàng nói riêng và niềm tin vào sự uy tín của Hitadoor
                        từ thị trường nói chung chính là động lực để Hitadoor không ngừng tiến bước,
                        góp phần cho các gia đình, doanh nghiệp hướng tới một cuộc sống tươi đẹp hơn.
                    </p>
                </div>
                <div class="row">
                    <div class="col-7"><img style={{ width: '100%', height: '100%' }} src="/gioithieu3.jpg"  alt="something"/></div>
                    <div className="col"></div>
                </div>
            </div><Footer /></>
        );
    }
}

export default introduce;