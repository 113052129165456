import React from "react";
import Footer from "../footer/footer";
export default function Top50() {
  const data = {
    code: "hitadoor-dat-top-50-thuong-hieu-manh-quoc-gia",
    name: "Hitadoor -Top 50 ",
    name2: "Thương hiệu mạnh quốc gia",
    image: "tintuc/top50.png",
    image1: "tintuc/top50/3.1.png",
    image2: "tintuc/top50/3.2.jpg",
    image3: "tintuc/top50/3.3.jpg",
  };
  return (
    <>
      <div className="container">
        <h2
          className="d-flex d-flex justify-content-center "
          style={{ color: "#1d4794" }}
        >
          {data.name}
        </h2>
        <h2
          className="d-flex d-flex justify-content-center "
          style={{ color: "#1d4794" }}
        >
          {data.name2}
        </h2>

        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor đã vinh dự nhận được bằng
          khen và cúp trong lễ công bố Thương Hiệu Mạnh Quốc Gia vào ngày
          21/04/2024.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "50%", height: "auto" }}
            src={`/${data.image1}`}
            alt={data.name}
          />
        </div>
        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Đây là một sự kiện quan trọng ghi
          nhận những nỗ lực và thành tựu nổi bật của công ty trong suốt thời
          gian qua. Đặc biệt, Hitadoor còn được xếp hạng trong top 50 thương
          hiệu mạnh, khẳng định vị thế và uy tín của mình trên thị trường.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "50%", height: "auto" }}
            src={`/${data.image2}`}
            alt={data.name}
          />
        </div>
        <ul>
          <b> Những điểm nổi bật của Hitadoor</b>
          <br />
          <br />
          <li>
            <b>Chất Lượng Sản Phẩm: </b>Hitadoor luôn chú trọng đến chất lượng
            sản phẩm, đảm bảo đáp ứng tốt nhất nhu cầu của khách hàng.
          </li>
          <li>
            <b>Dịch Vụ Khách Hàng: </b>Công ty cam kết mang đến dịch vụ khách
            hàng tận tâm và chuyên nghiệp.
          </li>
          <li>
            <b>Đổi Mới và Sáng Tạo: </b>Hitadoor liên tục đổi mới và sáng tạo để
            mang đến những giải pháp tiên tiến và hiệu quả nhất.
          </li>
          <li>
            <b>Đóng Góp Xã Hội:</b>Ngoài hoạt động kinh doanh, Hitadoor cũng
            tích cực tham gia vào các hoạt động cộng đồng và đóng góp cho xã
            hội.
          </li>
          <div className="d-flex justify-content-center">
            <img
              style={{ width: "50%", height: "auto" }}
              src={`/${data.image3}`}
              alt={data.name}
            />
          </div>
          </ul>
          
          <div className="d-flex d-flex justify-content-center mt-3">
            &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor xin gửi lời cảm ơn chân
            thành đến các đối tác, khách hàng và toàn thể nhân viên đã luôn đồng
            hành và ủng hộ. Sự công nhận này là động lực to lớn để chúng tôi
            tiếp tục phát triển và cống hiến nhiều hơn nữa.
          </div>
          <ul>
          <b>Hitadoor - Đối tác tin cậy của bạn!</b>
        </ul>
      </div>
      <Footer />
    </>
  );
}
