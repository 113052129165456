import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5810RS() {
    const productDetail = {
        code: '5810RS',
        name: '5810RS',
        image: 'germanygates/3_5810RS.png',
        structure: 'Nan hình hộp bản 58mm, móc dày 1,25mm, chân chịu lực dày 1,25mm. TL 10,6Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có cụm sọc nhỏ.',
        maximumSize: '6m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.178.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
