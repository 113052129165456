import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G701RS() {
    const productDetail = {
        code: '701RS',
        name: '701RS',
        image: 'germanygates/7_701RS.png',
        structure: 'Nan cong bản 71mm, móc dày 1.2mm, chân chịu lực dày 1.1mm. TL 8.2Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có cụm sọc nhỏ.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.625.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
