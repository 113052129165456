import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function RITO() {
    const productDetail = {
        code: 'rito',
        name: 'Bộ tời ngoài RITO',
        image: 'phukiens/phukiengermany/rito.png',
        category: 'Phụ kiện cửa Đức',
        imageDetail: 'phukiens/phukiengermany/ritocontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
