import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G070HE() {
    const productDetail = {
        code: '070HE',
        name: '070HE',
        image: 'germanygates/13_070HE.png',
        structure: 'Nan cong bản 75mm, móc dày 1mm, vai và chân chịu lực dày 1mm. TL 6,2Kg ± 6%',
        characteristic: 'Nan đơn 2 lớp, không khe thoáng. Bề mặt nan có 2 sọc.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "1.695.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
