import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5811RS() {
    const productDetail = {
        code: '5811RS',
        name: '5811RS',
        image: 'germanygates/4_5811RS.png',
        structure: 'Nan hình hộp bản 58mm, móc dày 1,35mm, chân chịu lực dày 1,35mm. TL 11,5Kg ± 6%',
        characteristic: ' 2 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có cụm sọc.',
        maximumSize: '7m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.480.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
