import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G515A() {
    const productDetail = {
        code: '515A',
        name: '515A',
        image: 'germanygates/11_515A.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,45mm, chân chịu lực dày 1,45mm. TL 11,7Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 1 vít, khe thoáng hình chữ nhật lớn. Bề mặt nan có 2 cụm sọc nhỏ.',
        maximumSize: '7,5m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.625.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
