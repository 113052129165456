import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G559R() {
    const productDetail = {
        code: '559R',
        name: '559R',
        image: 'germanygates/8_559R.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,2mm, chân chịu lực dày 1,15mm. TL 9,5Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 1 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 2 sọc lớn và 1 sọc nhỏ.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.865.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
