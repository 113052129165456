import { Component } from "react";
import "./header.css";
import React from "react";
class header extends Component {
  state = {};
  render() {
    return (
      <div className="header">
        <div className="row title">
          <div className="logo col-4" style={{ marginLeft: "15px" }}>
            <img
              src="/logo.png"
              style={{ width: "100%", height: "80%" }}
              alt=""
            />
          </div>

          <div className="title col row">
            
          </div>
        </div>
        <div className="row menubar">
          <div className="col "></div>
          <div className="col menu">
            <a href="/">Trang chủ</a>
          </div>
          <div className="col menu">
            <a href="/gioi-thieu">Giới thiệu</a>
          </div>
          <div className="col dropdown">
            <a href="/san-pham">Sản phẩm</a>
            <div class="dropdown-content">
              <a href="/cua-cuon-duc">Cửa cuốn Đức</a>
              <a href="/cua-cuon-uc">Cửa cuốn Úc</a>
              <a href="/cua-cuon-khac">Cửa cuốn khác</a>
              <a href="/phu-kien-cua">Phụ kiện cửa</a>
            </div>
          </div>
          <div className="col-2 menu">
            <a href="/hinh-anh-xuong-san-xuat">Hình ảnh xưởng sản xuất</a>
          </div>
          {/* <div className="col-2 menu">
            <a href="/bang-gia-cua-cuon">Bảng giá cửa cuốn</a>
          </div> */}
          <div className="col-2 menu">
            <a href="/tin-tuc-su-kien">Tin tức - Sự kiện</a>
          </div>
          <div className="col-2 menu">
            <a href="/tuyen-dung">Tuyển dụng</a>
          </div>
          <div className="col menu">
            <a href="/lien-he">Liên hệ</a>
          </div>
        </div>
      </div>
    );
  }
}

export default header;
