import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5223HS() {
    const productDetail = {
        code: '5223HS',
        name: '5223HS',
        image: 'germanygates/21_5223HS.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 1,5mm, chân chịu lực dày 1,7mm. TL 13,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có cụm 6 chỉ nhuyễn giữa thân nan và 2 sọc lớn 2 bên thân nan.',
        maximumSize: '6m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.605.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
