import React from "react";
import "./hinhanhxuongsanxuat.css";
import Footer from "./../footer/footer";
export default function Hinhanhxuongsanxuat() {
  return (
    <>
      <div className="container">
        <div class="d-flex justify-content-center">
          <h3 style={{ color: "#1d4794", fontWeight: "bold" }}>
            Hình Ảnh Xưởng Sản Xuất
          </h3>
        </div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/3.jpg" alt="something" /></div>
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/2.jpg" alt="something" /></div>
        </div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/1.jpg" alt="something" /></div>
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/4.jpg" alt="something" /></div>
        </div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/5.png" alt="something" /></div>
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/6.png" alt="something" /></div>
        </div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/7.jpg" alt="something" /></div>
            <div className="col">  <img className="image_" src="/hinhanhxuongsanxuat/8.jpg" alt="something" /></div>
        </div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="w-100" src="/hinhanhxuongsanxuat/9.jpg" alt="something" /></div>
        </div>
      </div>
      <Footer />
    </>
  );
}
