import React from "react";
import Footer from "../footer/footer";
import { DataView } from "primereact/dataview";
import "./tuyendung.css";
export default function Recruitment() {
  const RecruitmentData = [
    {
      code: "tuyen-dung-nhan-vien-nghien-cuu-va-phat-trien-thi-truong",
      name: "Tuyển Dụng Nhân Viên Nghiên Cứu và Phát Triển Thị Trường",
      image: "tuyendung.png",
    },
    {
      code: "tuyen-dung-nhan-vien-cham-soc-khach-hang",
      name: "Tuyển Dụng Nhân Viên Chăm Sóc Khách Hàng",
      image: "tuyendung.png",
    },
    {
      code: "tuyen-dung-chuyen-vien-marketing",
      name: "Tuyển Dụng Chuyên Viên Marketing",
      image: "tuyendung.png",
    },
    {
      code: "tuyen-dung-truong-phong-kinh-doanh",
      name: "Tuyển Dụng Trưởng Phòng Kinh Doanh",
      image: "tuyendung.png",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className="card  responsive_grid_item col-4" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/tuyen-dung/${product.code}`}
        >
          <div className="p-4">
            <div className="row">
              <div
                className=" text-2xl font-bold d-flex justify-content-center"
                style={{
                  color: "#1d4794",
                  fontWeight: "bold",
                }}
              >
                 {product.name}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "400px" }}
                src={`tuyendung/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="d-flex justify-content-center">
              <a
                style={{ textDecoration: "none",color:'black' }}
                className="m-0 "
                href={`/tuyen-dung/${product.code}`}
              >
                Xem thêm &gt; &gt; &gt;
              </a>
            </div>
          </div>
        </a>
      </div>
    );
  };
  return (
    <>
      <div className="mt-3">
        <DataView
          className="container"
          value={RecruitmentData}
          listTemplate={listTemplate}
        />
      </div>
      <Footer />
    </>
  );
}
