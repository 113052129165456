import React from "react";
import Footer from "../footer/footer";
export default function Letrungbay() {
  const data = {
    code: "hitadoor-nha-tai-tro-vang-tai-le-trung-bay-va-ra-mat-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-ho-chi-minh",
    name: "Hitadoor - Nhà tài trợ vàng tại Lễ Trưng Bày & Ra Mắt Hiệp Hội Cửa Việt Nam ",
    name2: " - Chi Hội Thành Phố Hồ Chí Minh vào ngày 18-19/05/2024",
    image: "tintuc/letrungbay/4.1.jpg",
    image1: "tintuc/letrungbay/4.2.jpg",
    image2: "tintuc/letrungbay/4.3.jpg",
    image3: "tintuc/letrungbay/4.4.jpg",
  };
  return (
    <>
      <div className="container">
        <h2
          className="d-flex d-flex justify-content-center text_news_letrungbai"
          style={{ color: "#1d4794" }}
        >
          {data.name}
        </h2>
        <h2
          className="d-flex d-flex justify-content-center text_news_letrungbai"
          style={{ color: "#1d4794" }}
        >
          {data.name2}
        </h2>
        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Với sự nổ lực và cam kết không ngừng
          nghỉ trong việc cung cấp các sản phẩm cửa chất lượng cao, chúng tôi
          vinh hạnh là nhà tài trợ vàng tại Lễ Trưng Bày & Ra Mắt Hiệp Hội Cửa
          Việt Nam - Chi Hội Thành Phố Hồ Chí Minh.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "70%", height: "auto" }}
            src={`/${data.image}`}
            alt={data.name}
          />
        </div>
        <div className="d-flex d-flex justify-content-center mt-3">
          Đại diện Hitadoor (thứ 3 từ trái sang) nhận kỷ niệm chương Nhà Tài Trợ
          Vàng
        </div>
        <div className="d-flex d-flex justify-content-center mt-3">
          của Hiệp Hội Cửa Việt Nam - Chi Hội Thành Phố Hồ Chí Minh
        </div>
        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Công ty chúng tôi xin gửi lời cảm ơn
          chân thành và sâu sắc nhất đến Quý Đại Lý - Quý Khách Hàng đã dành
          thời gian quý báu đến tham dự gian hàng trưng bày của chúng tôi trong
          sự kiện vừa qua.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "70%", height: "auto" }}
            src={`/${data.image1}`}
            alt={data.name}
          />
        </div>
        <div className=" mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Sự ủng hộ và quan tâm của quý khách
          là nguồn động lực lớn lao:
        </div>
        <div className="d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Sự hiện diện của quý khách tại gian
          hàng của chúng tôi không chỉ là niềm vinh dự mà còn là nguồn động viên
          to lớn giúp chúng tôi không ngừng nỗ lực cải tiến và phát triển các
          sản phẩm cửa cuốn với chất lượng tốt nhất.
        </div>
        <div className="d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Những ý kiến đóng góp và phản hồi từ
          quý khách là những thông tin vô cùng quý báu, giúp chúng tôi hoàn
          thiện hơn trong việc đáp ứng nhu cầu và mong đợi của thị trường.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "70%", height: "auto" }}
            src={`/${data.image2}`}
            alt={data.name}
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Chúng tôi hy vọng rằng sự kiện này đã
          mang lại cho quý khách nhiều thông tin bổ ích và cơ hội kết nối, hợp
          tác trong tương lai.
        </div>
        <div className="d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Chúng tôi cam kết sẽ tiếp tục nâng
          cao chất lượng sản phẩm và dịch vụ, đáp ứng tốt hơn nữa các yêu cầu và
          mong đợi của quý khách hàng. Sự hài lòng của quý khách là mục tiêu
          hàng đầu mà chúng tôi luôn hướng tới.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "70%", height: "auto" }}
            src={`/${data.image3}`}
            alt={data.name}
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Một lần nữa, chúng tôi xin chân thành
          cảm ơn Quý Đại Lý - Quý Khách Hàng đã đến tham dự và ủng hộ gian hàng
          trưng bày của chúng tôi. Chúng tôi mong rằng sẽ tiếp tục nhận được sự
          tin tưởng và ủng hộ của quý khách trong những sự kiện và hoạt động sắp
          tới.
        </div>
        <div class="video-container">
          <video controls>
            <source src="/video_tintuc.mp4" type="video/mp4" />
            Trình duyệt của bạn không hỗ trợ video.
          </video>
        </div>
      </div>
      <Footer />
    </>
  );
}
