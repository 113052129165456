import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G508HS() {
    const productDetail = {
        code: '508HS',
        name: '508HS',
        image: 'germanygates/19_508HS.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 1,2mm, chân chịu lực dày 1,1mm. TL 8,1Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có cụm chỉ nhuyễn nằm giữa thân nan.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.390.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
