import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G718HE() {
    const productDetail = {
        code: '718HE',
        name: '718HE',
        image: 'germanygates/14_718HE.png',
        structure: 'Nan cong bản 75mm, móc dày 1mm, chân chịu lực dày 1mm. TL 6,7Kg ± 6%',
        characteristic: 'Nan đơn 1 lớp, có 1 lỗ thoáng hình oval. Bề mặt nan có 2 sọc.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "1.805.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
