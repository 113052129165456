import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G557R() {
    const productDetail = {
        code: '557R',
        name: '557R',
        image: 'germanygates/5_557R.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,1mm, chân chịu lực dày 1mm. TL 8,5Kg ± 6%',
        characteristic: '2 ron giảm chấn, 3 chân, 1 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 2 sọc.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.730.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
