import React from "react";
import Footer from "../footer/footer";
export default function RecruitmentMKT() {
  return (
    <>
      <div className="container recruitment_content">
        <div class="d-flex justify-content-center">
          <h1 style={{ color: "#1d4794" }}>Tuyển Dụng Chuyên Viên Marketing</h1>
        </div>
        <div>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <img
            style={{ width: "200px", height: "28px" }}
            src="/logohitatrangden.png"
          ></img>{" "}
          đang tìm kiếm ứng viên tài năng và sáng tạo cho vị trí Chuyên Viên
          Marketing. Nếu bạn có kinh nghiệm và đam mê trong lĩnh vực marketing,
          hãy gia nhập đội ngũ của chúng tôi!
        </div>
        <ul>
          <li>
            <b>Vị Trí: </b>Chuyên Viên Marketing.
          </li>
          <li>
            <b>Số lượng: </b>02.
          </li>
          <li>
            <b>Địa điểm làm việc:</b> Tân Phú, TP. Hồ Chí Minh hoặc Bến Cát,
            Bình Dương.
          </li>
          <li>
            <b>Mô Tả Công Việc</b>
          </li>
          <div>
            - Xây dựng, quản lý và phân bổ ngân sách Digital Marketing đúng và
            hiệu quả;
          </div>
          <div>
            {" "}
            - Triển khai các hoạt động quảng cáo trên các kênh online Google
            Ads, Facebook ads, Banner Ads, Email Marketing, SEO, GDN, dưới nhiều
            hình thức quảng cáo CPC, CPA, CPL, CPM...;
          </div>
          <div>
            {" "}
            - Tìm kiếm danh mục các đơn vị cung cấp dịch vụ liên quan đến công
            cụ, phần mềm để triển khai các chương trình Digital Marketing;
          </div>
          <div>
            {" "}
            - Thu thập, quản lý, phân tích và sử dụng hệ thống dữ liệu hiệu quả;
          </div>{" "}
          <div>
            - Phối hợp với Phòng Thương Hiệu và nhà thầu quảng cáo để triển khai
            các công việc.
          </div>
          <div>
            {" "}
            - Các công việc khác trong phạm vi của digital marketing được cấp
            trên phân công.
          </div>
          <li>
            <b>Yêu Cầu Công Việc</b>
          </li>
          <div>
            {" "}
            <b>- Trình độ:</b>Cao đẳng hoặc đại học trở lên, các chuyên ngành
            Kinh tế, Tài chính, Marketing, Truyền thông hoặc các ngành liên
            quan.
          </div>
          <div>
            - Có liên quan, am hiểu về digital marketing (Quản trị fanpage,
            triển khai các công cụ quảng cáo khác như Email, Zalo, Cốc Cốc,
            SEO...)
          </div>
          <div>
            - Nhiệt huyết, quyết liệt. Ham học hỏi, cầu tiến, chủ động cập nhật
            các kiến thức về công nghệ, thông tin thị trường, người tiêu dùng.
          </div>
          <div>
            - Hiểu biết về lĩnh vực vật liệu xây dựng, chủ động, tư duy logic và
            tư duy quy hoạch, giải quyết vấn đề tốt.
          </div>
          <li>
            <b>Quyền lợi</b>
          </li>
          <div>
            <b> - Lương + Thưởng:</b> Lương cơ bản + doanh số bán hàng
          </div>
          <div>
            <b> - Phúc lợi:</b> Bảo hiểm xã hội, bảo hiểm y tế theo chính sách
            lao động của nhà nước.
          </div>
        </ul>
        <b>Vui lòng liên hệ chúng tôi để biết thêm chi tiết: </b>
        <b>0913 211 616 (Mr. Phi)</b>
      </div>
      <Footer />
    </>
  );
}
