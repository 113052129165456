import React from "react";
import Footer from "../footer/footer";
export default function RecruitmentTPKD() {
  return (
    <>
      <div className="container recruitment_content">
        <div class="d-flex justify-content-center">
          <h1 style={{ color: "#1d4794" }}> Tuyển Dụng Trưởng Phòng Kinh Doanh</h1>
        </div>
        <div>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
          <img
            style={{ width: "200px", height: "28px" }}
            src="/logohitatrangden.png"
          ></img>{" "}
          đang tìm kiếm ứng viên có năng lực và kinh nghiệm để đảm nhiệm vị trí
          Trưởng Phòng Kinh Doanh. Nếu bạn đam mê kinh doanh và có kinh nghiệm
          trong lĩnh vực vật liệu xây dựng, hãy gia nhập đội ngũ của chúng tôi!
        </div>
        <ul>
          <li>
            <b>Vị Trí: </b>Trưởng Phòng Kinh Doanh.
          </li>
          <li>
            <b>Số lượng: </b>01.
          </li>
          <li>
            <b>Địa điểm làm việc:</b> Tân Phú, TP. Hồ Chí Minh hoặc Bến Cát,
            Bình Dương.
          </li>
          <li>
            <b>Mô Tả Công Việc</b>
          </li>
          <div>
            <b>- Lập kế hoạch kinh doanh:</b> Xây dựng và triển khai các chiến
            lược kinh doanh để đạt mục tiêu doanh số.
          </div>
          <div>
            <b>- Quản lý đội ngũ:</b> Tuyển dụng, đào tạo và quản lý đội ngũ
            nhân viên kinh doanh để đảm bảo hiệu quả làm việc.
          </div>
          <div>
            <b>- Phát triển thị trường:</b> Tìm kiếm và phát triển quan hệ với
            khách hàng, mở rộng thị trường tiêu thụ.
          </div>
          <div>
            <b>- Phân tích thị trường:</b> Nghiên cứu thị trường, đối thủ cạnh
            tranh và xu hướng phát triển ngành.
          </div>{" "}
          <div>
            <b>- Báo cáo:</b> Thực hiện báo cáo định kỳ về tình hình kinh doanh
            và đề xuất các giải pháp cải thiện.
          </div>
          <li>
            <b>Yêu Cầu Công Việc</b>
          </li>
          <div>
            <b>- Trình độ:</b> Tốt nghiệp Đại học các ngành Kinh tế, Quản trị
            Kinh doanh hoặc các ngành liên quan.
          </div>
          <div>
            <b>- Kinh nghiệm:</b> Ít nhất 2 năm kinh nghiệm trong lĩnh vực kinh
            doanh, ưu tiên trong ngành vật liệu xây dựng.
          </div>
          <li>
            <b>Quyền lợi</b>
          </li>
          <div>
            <b> - Lương:</b> Lương cơ bản 20-30tr (Thoả thuận khi phỏng vấn, tuỳ
            theo năng lực).
          </div>
          <div>
            <b> - Thưởng:</b> Thưởng thêm nếu có sáng kiến mới, doanh số tháng
            vượt tiêu chuẩn.
          </div>
          <div>
            <b> - Phúc lợi:</b> Bảo hiểm xã hội, bảo hiểm y tế theo chính sách
            lao động của nhà nước.
          </div>
        </ul>
        <b>Vui lòng liên hệ chúng tôi để biết thêm chi tiết: </b>
        <b>0913 211 616 (Mr. Phi)</b>
      </div>
      <Footer />
    </>
  );
}
