import React from "react";
import Footer from "../footer/footer";
export default function Hiephoi() {
  const data = {
    code: "hitadoor-tham-gia-trien-lam-trung-bay-cua-cua-hiep-hoi-cua-viet-nam-chi-hoi-thanh-pho-can-tho",
    name: "Hitadoor Tham Gia Triển Lãm Trưng Bày Cửa Của ",
    name2: " Hiệp Hội Cửa Việt Nam - Chi Hội TP Cần Thơ vào ngày 19-20/07/2024",
    image: "tintuc/hiephoi/5.png",
    image1: "tintuc/hiephoi/5.1.png",
    image2: "tintuc/hiephoi/5.2.png",
    image3: "tintuc/hiephoi/5.3.png",
  };
  return (
    <>
      <div className="container">
        <h2
          className="d-flex d-flex justify-content-center text_news"
          style={{ color: "#1d4794" }}
        >
          {data.name}
        </h2>
        <h2
          className="d-flex d-flex justify-content-center text_news"
          style={{ color: "#1d4794" }}
        >
          {data.name2}
        </h2>

        <div className="d-flex d-flex justify-content-center mt-3">
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor đã tham gia triển lãm trưng
          bày cửa do Hiệp Hội Cửa Việt Nam - Chi Hội Thành Phố Cần Thơ tổ chức
          ngày 19-20/07/2024. Đây là một cơ hội tuyệt vời để Hitadoor giới thiệu
          các sản phẩm chất lượng cao và gặp gỡ các đại lý, đối tác, khách hàng
          tiềm năng.
        </div>
        <div className="d-flex justify-content-center">
          <img
            style={{ width: "80%", height: "auto" }}
            src={`/${data.image1}`}
            alt={data.name}
          />
        </div>
        <br/>
        <div className="row">
          <div className="col"></div>
          <div className="col-10 contentParagraph">
            {" "}
            <ul>
              <b> Hoạt Động Của Hitadoor Tại Triển Lãm:</b>
              <li>
                <b>Trưng Bày Sản Phẩm: </b>Hitadoor mang đến triển lãm những sản
                phẩm cửa chất lượng cao, từ các mẫu cửa truyền thống đến hiện
                đại.
              </li>
              <li>
                <b>Giới Thiệu Công Nghệ: </b>Trưng bày các công nghệ mới nhất
                trong sản xuất và lắp đặt cửa.
              </li>
              <li>
                <b>Tư Vấn Chuyên Nghiệp: </b>Đội ngũ nhân viên của Hitadoor sẵn
                sàng tư vấn và giải đáp mọi thắc mắc của khách hàng về sản phẩm
                và dịch vụ.
              </li>
              <li>
                <b>Chương Trình Khuyến Mãi:</b>Nhiều chương trình khuyến mãi và
                ưu đãi đặc biệt dành cho khách hàng tham quan gian hàng của
                Hitadoor.
              </li>
              <br/>
              <div className="d-flex justify-content-center">
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={`/${data.image2}`}
                  alt={data.name}
                />
              </div>
              <br/>
              <b> Mục Tiêu Và Kỳ Vọng:</b>
              <div>
                Hitadoor hy vọng thông qua triển lãm lần này, chúng tôi có thể:
              </div>
              <li>
                <b>Mở Rộng Thị Trường:</b>Tăng cường sự hiện diện tại khu vực
                miền Tây và các tỉnh lân cận.
              </li>
              <li>
                <b>Xây Dựng Quan Hệ: </b>Thiết lập và củng cố mối quan hệ với
                các đối tác và khách hàng.
              </li>
              <li>
                <b>Nhận Phản Hồi: </b>Thu thập ý kiến và phản hồi từ khách hàng
                để cải thiện và nâng cao chất lượng sản phẩm, dịch vụ.
              </li>
              <br/>
              <div className="d-flex justify-content-center">
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={`/${data.image3}`}
                  alt={data.name}
                />
              </div>
              <br/>
              <b>Lời Cảm Ơn:</b>
              </ul>
              <div>
                &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Hitadoor xin gửi lời tri ân đến
                các khách hàng và đối tác đã đến tham quan và ủng hộ gian hàng
                trưng bày của chúng tôi.
              </div>
              <ul>
              <b>Hitadoor - Chất Lượng Hàng Đầu, Dịch Vụ Chuyên Nghiệp!</b>
            </ul>
          </div>
          <div className="col"></div>
        </div>
      </div>
      <Footer />
    </>
  );
}
