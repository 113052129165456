import React from 'react'
import UcGateDetail from './ucgateDetail'
export default function BAP() {
    const productDetail = {
        code: 'b_ap',
        name: 'B_AP',
        image: 'ucgates/b_ap/bapb3.png',
        category: 'Cửa cuốn Úc',
        image1: 'ucgates/b_ap/bapb1.png',
        image2: 'ucgates/b_ap/bapb3.png',
        image3: 'ucgates/b_ap/bapb1.png',
        image4: 'ucgates/b_ap/bapb3.png',
        image5: 'ucgates/b_ap/bapb1.png',
        doorSlat: 'Thép hợp kim mạ màu hãng BlueScope (APEX), dày 0,53mm (mạ sơn mờ, phủ polyeste).',
        color: 'Xanh rêu - Xám mờ',
        maximumSize: '5,5m x 5m',
        navRay: 'Ray sắt U65 x 1,2mm',
        price: "1.200.000",
    }
    return (
        <UcGateDetail productDetail={productDetail} />
    )
}
