import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5511R() {
    const productDetail = {
        code: '5511R',
        name: '5511R',
        image: 'germanygates/10_5511R.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,4mm, chân chịu lực dày 1,4mm. TL 11,3Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 1 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 3 sọc.',
        maximumSize: '7m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.460.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
