import React from "react";
import Footer from "../../footer/footer";

export default function Cuacuonluoi() {
  const productDetail = {
    code: "cuacuonluoi",
    name: "Cửa cuốn lưới",
    image: "cuacuonluoi/1.jpg",
    category: "Cửa cuốn khác",
    price: "1.150.000",
  };
  return (
    <>
      <div className="container mt-2">
        <div className="row product">
          <div className=" col-6 mt-5 responsive_grid_item">
            <img
              className="responsive_item_img"
              style={{ height: "400px" }}
              alt={`${productDetail.code}`}
              src={`/${productDetail.image}`}
            />
          </div>
          <div className="detail mt-3 col-6 responsive_grid_item">
            <h1
              className="row d-flex justify-content-center"
              style={{ color: "#1d4794" }}
            >{`${productDetail.name}`}</h1>
             <div className="row d-inline">
              <b>Giá sản phẩm:</b>
                {" "}
              <span style={{ color: "red" }}>
                {`${productDetail.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
            <br />
            <b>Cửa cuốn lưới</b>
            <div>-Cửa cuốn lưới song ngang</div>
            <div>-Cửa cuốn lưới mắc võng</div>
            <br />
            <b>Cửa cuốn lưới song ngang/ mắc võng</b>
            <div>-Cửa cuốn lưới song ngang inox</div>
            <div>-Cửa cuốn lưới song ngang sơn tĩnh điện</div>
            <br />
            <b>Thông số kỹ thuật cửa cuốn lưới mắc võng</b>
            <div>– Thân cửa bằng inox 304 có độ cứng cao, tăng khả năng bảo vệ công
              trình.</div>
            <div>– Màu sắc: màu inox hoặc màu sơn tĩnh điện (kem, ghi, cafe)</div>
            <div> – Bề mặt sáng, hạn chế tối đa sự gỉ sét, oxy hóa của môi trường.</div>
            <div>– Tạo sự thoáng mát, không gian rộng cho ngôi nhà</div>
            <div>– Thích hợp sử dụng cho cửa hàng, TT thương mại, ngân hàng, gara …</div>
            <div>– Mắc võng, Inox 304, Phi 12,7, dày 1mm</div>
          </div>
        </div>
        <div className=' d-flex justify-content-center'><b style={{ fontSize: '36px' }}>Hình ảnh cửa cuốn lưới mắc võng</b></div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image" src="/cuacuonluoi/4.png" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/5.png" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/mv.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/mvv.jpg" alt="something" /></div>

        </div>
        <div className=' d-flex justify-content-center'><b style={{ fontSize: '36px' }}>Hình ảnh cửa cuốn lưới song ngang tròn</b></div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image" src="/cuacuonluoi/6.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/7.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/snt.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/sntt.jpg" alt="something" /></div>
           
        </div>
        <div className=' d-flex justify-content-center'><b style={{ fontSize: '36px' }}>Hình ảnh cửa cuốn lưới song ngang vuông</b></div>
        <div class="d-flex align-content-between flex-wrap">
            <div className="col">  <img className="image" src="/cuacuonluoi/snv.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/snvv.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/snvvv.jpg" alt="something" /></div>
            <div className="col">  <img className="image" src="/cuacuonluoi/snvvvv.jpg" alt="something" /></div>
           
        </div>
      </div>
      <Footer />
    </>
  );
}
