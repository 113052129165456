import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function PUMA() {
    const productDetail = {
        code: 'puma',
        name: 'Bộ tời ngoài PUMA',
        image: 'phukiens/phukiengermany/puma.png',
        category: 'Phụ kiện cửa Đức',
        imageDetail: 'phukiens/phukiengermany/pumacontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
