import { Galleria } from "primereact/galleria";
import React from "react";
import Footer from "./../footer/footer";
import { Divider } from "primereact/divider";
import { DataView } from "primereact/dataview";
import "./homecss.css";
export default function homepage() {
 
  const images = [
    {
      itemImageSrc: "/banners/1.png",
      alt: "banner1",
    },
    {
      itemImageSrc: "/banners/2.png",
      alt: "banner2",
    },
    {
      itemImageSrc: "/banners/3.png",
      alt: "banner3",
    },
    {
      itemImageSrc: "/banners/4.png",
      alt: "banner4",
    },
    {
      itemImageSrc: "/banners/5_km.png",
      alt: "banner5",
    },
  ];

  //News
  const NewsData = [
    {
      code: "hitadoor-dat-top-100-thuong-hieu-chau-a-thai-binh-duong",
      name: "Hitadoor Đạt Top 100 Thương Hiệu ",
      name2: "Châu Á - Thái Bình Dương Vào Ngày 22/10/2022",
      image: "tintuc/top100/2.jpg",
    },
    {
      code: "hitadoor-dat-top-50-thuong-hieu-manh-quoc-gia",
      name: "Hitadoor -Top 50 ",
      name2: "Thương hiệu mạnh quốc gia",
      image: "tintuc/top50/3.png",
    },
  ];
  const itemTemplate = (product) => {
    if (!product) {
      return;
    }
    return gridItem(product);
  };
  const listTemplate = (products) => {
    return (
      <div className="row">
        {products.map((product, index) => itemTemplate(product, index))}
      </div>
    );
  };
  const gridItem = (product) => {
    return (
      <div className="card responsive_grid_item col-6" key={product.code}>
        <a
          style={{ textDecoration: "none" }}
          href={`/tin-tuc-su-kien/${product.code}`}
        >
          <div className="p-4">
            <div className="row">
              <div
                className=" text-2xl font-bold d-flex justify-content-center"
                style={{
                  color: "#1d4794",
                  fontWeight: "bold",
                }}
              >
                {product.name}
              </div>
              <div
                className=" text-2xl font-bold d-flex justify-content-center"
                style={{
                  color: "#1d4794",
                  fontWeight: "bold",
                }}
              >
                {product.name2}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <img
                style={{ width: "100%", height: "400px" }}
                src={`/${product.image}`}
                alt={product.name}
              />
            </div>
            <div className="d-flex justify-content-center">
              <a
                style={{ textDecoration: "none", color: "black" }}
                className="m-0 "
                href={`/tin-tuc-su-kien/${product.code}`}
              >
                Xem thêm &gt; &gt; &gt;
              </a>
            </div>
          </div>
        </a>
      </div>
    );
  };
  //end News
  const itemGalleria = (item) => {
    return (
      <img
        className="mt-3"
        src={item.itemImageSrc}
        alt={item.alt}
        style={{ width: "100%", height: "auto", display: "block" }}
      />
    );
  };

  return (
    <>
      <div className="w-100">
        <div>
          <Galleria
            value={images}
            numVisible={5}
            circular
            showItemNavigators
            showItemNavigatorsOnHover
            showThumbnails={false}
            item={itemGalleria}
            autoPlay
            transitionInterval={2000}
          />
        </div>
        <div className="discount">
          <div className="row">
            <b
              className="col productTitle"
              style={{ fontSize: "33px", color: "#1d4794" }}
            >
              KHUYẾN MÃI
            </b>
            <div className="col-10 mb-2 divline"></div>
          </div>
          <div class="row mt-2">
            <div className="col"></div>
            <div className="responsive_km col-8">
              <img
                style={{ width: "100%", height: "100%" }}
                alt="khuyenmai"
                src="/banners/5_km.png"
              />
            </div>

            <div className="col"></div>
          </div>
        </div>
        <div className="condition">
          <div className="row">
            <div className="col-3 productTitle">
              <b style={{ fontSize: "36px", color: "#1d4794" }}>
               ĐIỀU KIỆN ÁP DỤNG
              </b>
            </div>
            <div className="col mb-1 divline"></div>
          </div>
          <div className="row conditionText">
            <p className="col" style={{ marginLeft: "30px" }}>
              Khi khách hàng mua cửa cuốn Đức nguyên bộ, bao gồm :
            </p>
            <div className="col row">
              <div className="row">- Lá cửa Đức</div>
              <div className="row">- Motor</div>
              <div className="row">- Remote</div>
              <div className="row">- Các phụ kiện đi kèm</div>
            </div>
          </div>
         
        </div>
        <div className="why-section row">
            <div className="col mb-4 divline"></div>
            <p
              className="col-5 productTitle"
              style={{ fontWeight: "bold", fontSize: "36px", color: "#1d4794" }}
            >
              TẠI SAO NÊN CHỌN HITADOOR
            </p>
          </div>
          <div className="row">
            {/* <img
              className="col-5 responsive_grid_item"
              src="/6_lydonenchonhitadoor.png"
              alt="something"
            ></img> */}
          </div>
          <div className="row image">
          &nbsp; &nbsp; &nbsp; &nbsp;
            <img
              className="col image_reason"
              src="/maumadadang.png"
              alt="something"
            ></img>
            <img
              className="col image_reason"
              src="/lydo2.png"
              alt="something"
            ></img>
            <img
              className="col image_reason"
              src="/lydo3.png"
              alt="something"
            ></img>
          </div>
          <div className="row image mt-2">
          &nbsp; &nbsp; &nbsp; &nbsp;
            <img
              className="col image_reason"
              src="/lydo4.png"
              alt="something"
            ></img>
            <img
              className="col image_reason"
              src="/lydo5.png"
              alt="something"
            ></img>
            <img
              className="col image_reason"
              src="/lydo6.png"
              alt="something"
            ></img>
          </div>
          <div className="news row">
            <p
              className="col-3 productTitle"
              style={{
                fontWeight: "bolder",
                fontSize: "40px",
                color: "#1d4794",
              }}
            >
              TIN TỨC-SỰ KIỆN
            </p>
            <div className="col-9 mb-4 divline"></div>
          </div>
          <div className="container">
            <DataView
              className="container"
              value={NewsData}
              listTemplate={listTemplate}
            />
          </div>
          <Divider align="center">
            <div className="inline-flex align-items-center productTitle">
              <img alt="location" src="/location.png" />
              <b className="location_text" style={{ color: "#1d4794" }}>
                VỊ TRÍ CÔNG TY
              </b>
            </div>
          </Divider>
          <div className="d-flex justify-content-center responsive_grid_item">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.9799486673537!2d106.62024977457537!3d10.812846158521856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752be72eae10eb%3A0x5b82619bac5718e2!2zMTkxIE5ndXnhu4VuIEjhu691IFRp4bq_biwgVMOieSBUaOG6oW5oLCBUw6JuIFBow7osIEjhu5MgQ2jDrSBNaW5oLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1720984214810!5m2!1svi!2s"
              width="400"
              height="300"
              title="Địa chỉ show room"
              style={{ border: 0, marginLeft: "40px" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <p
            className="d-flex justify-content-center responsive_text"
            style={{ fontWeight: "bolder", color: "#1d4794" }}
          >
            CÁC CHI NHÁNH CÔNG TY ĐÃ VÀ ĐANG ĐƯỢC MỞ RỘNG TRÊN TOÀN QUỐC
          </p>
      </div>
      <Footer />
    </>
  );
}
