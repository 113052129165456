import React from 'react'
import AcessoryDetail from './accessoryDetail'
export default function YH() {
    const productDetail = {
        code: 'yh',
        name: 'Bộ tời ngoài YH',
        image: 'phukiens/phukiengermany/yh.png',
        category: 'Phụ kiện cửa Đức',
        imageDetail: 'phukiens/phukiengermany/yhcontent.png',
    }
    return (
        <AcessoryDetail productDetail={productDetail} />
    )
}
