import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5446HL() {
    const productDetail = {
        code: '5446HL',
        name: '5446HL',
        image: 'germanygates/26_5446HL.png',
        structure: 'Nan kép hình hộp bản 25 + 52mm, móc dày 1,5mm, chân chịu lực dày 1,9mm. TL 15,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, nan kép phối 2 màu cafe + kem, khe thoáng hình chữ nhật lớn. Bề mặt nan lớn: có 1 cụm 3 chỉ, bề mặt nan nhỏ: có 2 sọc.',
        maximumSize: '7m x 5m',
        category: 'Cửa cuốn Đức',
        price: "4.350.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
