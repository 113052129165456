import React from "react";
import Footer from "../../footer/footer";
import { Galleria } from "primereact/galleria";
import UcAdvantage from "./UcAdvantage";
export default function UcGateDetail(props) {
  const productDetail = props.productDetail;

  const images = [
    { itemImageSrc: "" },
    { itemImageSrc: "" },
    { itemImageSrc: "" },
    { itemImageSrc: "" },
    { itemImageSrc: "" },
  ];
  if (productDetail.image1.length > 0) {
    images[0].itemImageSrc = "/" + productDetail.image1;
  }
  if (productDetail.image2.length > 0) {
    images[1].itemImageSrc = "/" + productDetail.image2;
  }
  if (productDetail.image3.length > 0) {
    images[2].itemImageSrc = "/" + productDetail.image3;
  }
  if (productDetail.image4.length > 0) {
    images[3].itemImageSrc = "/" + productDetail.image4;
  }
  if (productDetail.image5.length > 0) {
    images[4].itemImageSrc = "/" + productDetail.image5;
  }

  const itemTemplate = (item) => {
    return (
      <img
        className="mt-3"
        src={item.itemImageSrc}
        alt="Something"
        style={{ width: "100%", height: "400px", display: "block" }}
      />
    );
  };
  return (
    <>
      <div className="container mt-2">
        <div className="row product">
          <div className=" col-7 responsive_grid_item">
            <Galleria
              value={images}
              numVisible={5}
              circular
              showItemNavigators
              showItemNavigatorsOnHover
              showThumbnails={false}
              item={itemTemplate}
              autoPlay
              transitionInterval={2000}
            />
          </div>
          <div className="detail mt-3 col-5 responsive_grid_item">
            <h1
              className="row d-flex justify-content-center"
              style={{ color: "#1d4794" }}
            >{`${productDetail.name}`}</h1>
            <div className="row d-inline">
              <b>Giá sản phẩm:</b>
                {" "}
              <span style={{ color: "red" }}>
                {`${productDetail.price}`} VNĐ/m<sup>2</sup>
              </span>
            </div>
            <br />
            <div className="row d-inline">
              <b>Nan cửa:</b>
              {`${productDetail.doorSlat}`}
            </div>
            <br />
            <div className="row d-inline">
              <b>Màu sắc:</b>
              {`${productDetail.color}`}
            </div>
            <br />
            <div className="row d-inline">
              <b>Kích thước tối đa(WxH):</b> {`${productDetail.maximumSize}`}
            </div>
            <br />
            <div className="row d-inline">
              <b>Ray dẫn hướng:</b> {`${productDetail.navRay}`}
            </div>
          </div>
        </div>
        <UcAdvantage />
      </div>
      <Footer />
    </>
  );
}
