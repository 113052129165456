import { Component } from "react";
import React from "react";
import './footer.css';
class Footer extends Component {
    state = {}
    render() {
        return (
            <div className="footer">
{/* 
                <div className="logo row">
                    <div className="col"></div>
                    <div className="col-3"> <img src='/logo.png' style={{ width: "100%", height: "80%" }} alt="" /></div>
                    <div className="col"></div>
                </div> */}
                <div className="row info mt-2">
                    <div className="col-3"></div>
                    <div className="col-7 footer_content_responsive">
                        <div className="row footer_content_responsive">
                            <div className="col"style={{ fontWeight:'bold' }}>Office</div>
                            <div className="col-10" style={{ textAlign: 'left' }}>:191 Nguyễn Hữu Tiến, phường Tây Thạnh, quận Tân Phú, thành phố Hồ Chí Minh</div>
                        </div>
                        <div className="row footer_content_responsive">
                            <div className="col-2 text_showroom" style={{ fontWeight:'bold' }}>Showroom</div>
                            <div className="col-10" style={{ textAlign: 'left' }}>:832 Đại Lộ Bình Dương, thành phố Bến Cát, tỉnh Bình Dương</div>
                        </div>
                        <div className="row footer_content_responsive">
                            <div className="col" style={{ fontWeight:'bold' }}>Factory</div>
                            <div className="col-10" style={{ textAlign: 'left' }}>:Khu CN Mỹ Phước 1, thành phố Bến Cát, tỉnh Bình Dương</div>
                        </div>
                        <div className="row footer_content_responsive">
                            <div className="col" style={{ fontWeight:'bold' }}>Hotline</div>
                            <div className="col-10" style={{ textAlign: 'left' }}>:0913 211 616 - 0918 045 005</div>
                        </div>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
              
            </div >
        );
    }
}

export default Footer;