import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5224HP() {
    const productDetail = {
        code: '5224HP',
        name: '5224HP',
        image: 'germanygates/22_5224HP.png',
        structure: 'Nan hình hộp bản 52mm, móc dày 1,5mm, chân chịu lực dày 1,8mm. TL 14,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật lớn. Bề nan có sọc lớn chia đều thân nan.',
        maximumSize: '7m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.805.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
