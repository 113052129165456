import React from 'react'
import Footer from '../../footer/footer'
import './ucGate.css'
import { DataView } from 'primereact/dataview';

export default function UcGate() {
    const AustraliaGates = [
        {
          code: "b_ap",
          name: "B_AP",
          image: "ucgates/b_ap/b3.png",
          category: "Cửa cuốn Úc",
          price: "1.200.000",
        },
        {
          code: "b_colo",
          name: "B_COLO",
          image: "ucgates/b_colo/b4.png",
          category: "Cửa cuốn Úc",
          price: "1.400.000",
        },
        {
          code: "b_mpp",
          name: "B_MPP",
          image: "ucgates/b_mpp/b1.png",
          category: "Cửa cuốn Úc",
          price: "1.100.000",
        },
        {
          code: "eco_1",
          name: "ECO_1",
          image: "ucgates/eco_1/b2.png",
          category: "Cửa cuốn Úc",
          price: "980.000",
        },
        {
          code: "eco_2",
          name: "ECO_2",
          image: "ucgates/eco_2/e1.png",
          category: "Cửa cuốn Úc",
          price: "1.025.000",
        },
      ];
    const itemTemplate = (product) => {
        if (!product) {
            return;
        }
        return gridItem(product);
    };
    const listTemplate = (products) => {
        return <div className="row">{products.map((product, index) => itemTemplate(product, index))}</div>;
    };
    const gridItem = (product) => {
        return (
            <div className=" card responsive_grid_item col-4" key={product.code}>
                <a style={{ textDecoration: 'none' }} href={`/san-pham/${product.code}`}>
                    <div className="p-4">
                        <div className="d-flex justify-content-center">
                            <img style={{ width: '100%', height: '300px' }} src={`/${product.image}`} alt={product.name} />
                        </div>
                        <div className='row'>
                            <div className='col'></div>
                            <div className="d-flex justify-content-center col-7 text-2xl font-bold" style={{ color: '#1d4794', fontWeight: 'bold' }}>{product.name}</div>
                            <div className='col'></div>
                            <span style={{color:'red'}}>{`${product.price}`} VNĐ/m<sup>2</sup></span>
                        </div>
                    </div>
                </a>
            </div>
        );
    };
    return (
        <><div>
            <div className='d-flex justify-content-center'><b style={{ fontSize: '36px', color:'red' }}>Cửa Cuốn Tấm Liền</b></div>
            <DataView className='container' value={AustraliaGates} listTemplate={listTemplate} />
        </div><Footer /></>
    )
}
