import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5442HL() {
    const productDetail = {
        code: '5442HL',
        name: '5442HL',
        image: 'germanygates/25_H442HL.png',
        structure: 'Nan kép hình hộp bản 27 + 50mm, móc dày 1,3mm, chân chịu lực dày 1,4mm. TL 11,5Kg ± 6%',
        characteristic: '1 ron giảm chấn, 2 chân, 2 vít, nan phối 2 màu cafe + kem, khe thoáng hình chữ nhật lớn. Bề mặt nan có 1 cụm 5 chỉ giữa thân nan.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "3.360.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
