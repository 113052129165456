import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G541HE() {
    const productDetail = {
        code: '541HE',
        name: '541HE',
        image: 'germanygates/17_541HE.png',
        structure: 'Bản 62mm, thân nan dày 1,8mm, chân dày 1,1mm, móc dày 1,3mm. TL 9,5Kg ± 6% ',
        characteristic: '22 ron giảm chấn, lỗ thoáng hình chữ nhật lớn + ngôi sao (nếu lỗ thoáng dập so le thì không dập ngôi sao). Bề mặt nan có 2 chỉ nhỏ. Có thể gắn kèm lớp mica chống bụi giữa thân nan: phụ thêm 150.000đ/m2.',
        maximumSize: '5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.580.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
