import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G5510R() {
    const productDetail = {
        code: '5510R',
        name: '5510R',
        image: 'germanygates/9_5510R.png',
        structure: 'Nan hình hộp bản 55mm, móc dày 1,25mm, chân chịu lực dày 1,35mm. TL 10,3Kg ± 6%',
        characteristic: '2 ron giảm chấn, 2 chân, 1 vít, khe thoáng hình chữ nhật nhỏ. Bề mặt nan có 2 sọc.',
        maximumSize: '6m x 6m',
        category: 'Cửa cuốn Đức',
        price: "3.115.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
