import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G588RS() {
    const productDetail = {
        code: '588RS',
        name: '588RS',
        image: 'germanygates/1_588RS.png',
        structure: 'Nan hình hộp bản 58mm, móc dày 1mm, chân chịu lực dày 1mm. TL 8,4Kg ± 6%',
        characteristic: ' 2 ron giảm chấn, 2 chân, 2 vít, khe thoáng hình chữ nhật nhỏ + ngôi sao. Bề mặt nan có rãnh lõm 8mm và 2 sọc 2 bên chia đều thân nan.',
        maximumSize: '4,5m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.710.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
