import React from 'react'
import UcGateDetail from './ucgateDetail'
export default function ECO2() {
    const productDetail = {
        code: 'eco_2',
        name: 'ECO_2',
        image: 'ucgates/eco_2/eco2e1.png',
        category: 'Cửa cuốn Úc',
        image1: 'ucgates/eco_2/eco2e1.png',
        image2: 'ucgates/eco_2/eco2e3.png',
        image3: 'ucgates/eco_2/eco2e1.png',
        image4: 'ucgates/eco_2/eco2e3.png',
        image5: 'ucgates/eco_2/eco2e1.png',
        doorSlat: 'Thép hợp kim liên doanh Đài Loan, dày 0,5mm (mạ sơn bóng, phủ polyeste).',
        color: 'Xanh ngọc - Xám',
        maximumSize: '5m x 4m',
        navRay: 'Ray sắt U65 x 1,2mm',
        price: "1.025.000",
    }
    return (
        <UcGateDetail productDetail={productDetail} />
    )
}
