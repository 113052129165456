import React, { useState } from "react";
import "./test.css";
export default function TestMenu() {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="header">
      <div className="row title">
        {" "}
        <div
          className="logo col-3 responsivelogo"
          style={{ marginLeft: "15px" }}
        >
          <a href="/">
            <img
              src="/logo.png"
              style={{ width: "100%", height: "80%" }}
              alt=""
            />
          </a>
        </div>
        <div className="title col row"></div>
      </div>
      <div className="row menubar">
        <div className="col"></div>
        <div className={`col menu ${showMenu ? "show" : ""}`}>
          <a href="/">Trang chủ</a>
        </div>
        <div className={`col menu ${showMenu ? "show" : ""}`}>
          <a href="/gioi-thieu">Giới thiệu</a>
        </div>
        <div className={`col dropdown ${showMenu ? "show" : ""}`}>
          <a href="/san-pham">Sản phẩm</a>
          <div className="dropdown-content">
            <a href="/cua-cuon-duc">Cửa Cuốn Khe Thoáng Hợp Kim Nhôm</a>
            <a href="/cua-cuon-uc">Cửa Cuốn Tấm Liền</a>
            <a href="/cua-cuon-khac">Cửa cuốn khác</a>
            <a href="/phu-kien-cua">Phụ kiện cửa</a>
          </div>
        </div>
        <div className={`col-2 menu ${showMenu ? "show" : ""}`}>
          <a href="/hinh-anh-xuong-san-xuat">Hình ảnh xưởng sản xuất</a>
        </div>
        <div className={`col-2 menu ${showMenu ? "show" : ""}`}>
          <a href="/tin-tuc-su-kien">Tin tức - Sự kiện</a>
        </div>
        <div className={`col-2 menu ${showMenu ? "show" : ""}`}>
          <a href="/tuyen-dung">Tuyển dụng</a>
        </div>
        <div className={`col-2 menu ${showMenu ? "show" : ""}`}>
          <a href="/lien-he">Liên hệ</a>
        </div>
        {/* Repeat the pattern for other menu items */}
        <div
          className={` menu toggle-menu ${showMenu ? "show" : ""}`}
          onClick={() => setShowMenu(!showMenu)}
        >
          <img
            src="/menuBtn.jpg"
            alt="something"
            style={{ width: "25px", height: "25px" }}
            className="menu-button"
          ></img>
        </div>
      </div>
    </div>
  );
}
