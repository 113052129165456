import React from 'react'
import GermanyGateDetail from './germanyGateDetail'
export default function G110HD() {
    const productDetail = {
        code: '110HD',
        name: '110HD',
        image: 'germanygates/28_110HD.png',
        structure: 'Nan cong bản lớn 110 mm + 34mm, thân nan dày 1,2mm, móc dày 1,6mm, chân chịu lực dày 1,1mm. TL 9,2Kg ± 6%',
        characteristic: 'Nan kép 1 lớp, không khe thoáng. Bề mặt nan có cụm sọc ở giữa thân nan.',
        maximumSize: '6m x 5m',
        category: 'Cửa cuốn Đức',
        price: "2.990.000",
    }
    return (
        <GermanyGateDetail productDetail={productDetail} />
    )
}
